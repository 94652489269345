import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getUsers } from '../../api/users'
import { Page, Row, UserCard } from '../core'
import { User } from '../../types/user'

const usersQuery = () => ({
  queryKey: ['users'],
  queryFn: async () => getUsers(),
})

export const usersLoader = (queryClient: any) => async () => {
  const query = usersQuery()
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export const UserList = () => {
  const { data: users } = useQuery(usersQuery())

  return (
    <Page>
      {users && users.length > 0 ? (
        <>
          {users.map((user: User) => (
            <Row key={user.id}>
              <Link to={`/user/${user.login}`} className="block p-4 hover:bg-slate-200 md:px-6">
                <UserCard avatarUrl={user.avatar_url} userName={user.login} />
              </Link>
            </Row>
          ))}
        </>
      ) : (
        <p>No users found</p>
      )}
    </Page>
  )
}
