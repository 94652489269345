import { cloneElement, PropsWithChildren, ReactElement, ReactNode } from 'react'

type ListProps = {
  items: ReactNode[]
}

const ListItem = ({ children }: PropsWithChildren) => (
  <li className="overflow-hidden text-ellipsis whitespace-nowrap">{children}</li>
)

const List = ({ items }: ListProps) => {
  const keyedListItems = items.map((item, key) => cloneElement(item as ReactElement, { key }))

  return (
    <ul className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2 md:grid-cols-3">
      {keyedListItems}
    </ul>
  )
}

export { List, ListItem }
