import { ReactNode } from 'react'
import cx from 'classnames'

type PageProps = {
  children: ReactNode
  className?: string
}

export const Page = ({ className = '', children }: PageProps) => {
  const classNames = cx(
    'mx-auto max-w-3xl overflow-hidden rounded-lg md:shadow-md md:my-5 md:border border-slate-200',
    {
      [className]: !!className,
    }
  )
  return <div className={classNames}>{children}</div>
}
