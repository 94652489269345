import { Children } from 'react'
import cx from 'classnames'

type ExternalLinkProps = {
  href: string
  children: React.ReactNode
  className?: string
}

export const ExternalLink = ({ href, children, className = '' }: ExternalLinkProps) => {
  const classNames = cx('underline hover:text-blue-600 hover:no-underline', {
    'flex items-center': Children.count(children) > 1,
    [className]: !!className,
  })
  return (
    <a href={href} rel="noopener noreferrer" target="_blank" className={classNames}>
      {children}
    </a>
  )
}
