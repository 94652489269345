import cx from 'classnames'

type AvatarProps = {
  src: string
  alt: string
  size?: 'default' | 'small'
}

export const Avatar = ({ src, alt, size = 'default' }: AvatarProps) => {
  const sizes = {
    small: 'w-6 mr-1',
    default: 'w-20 mr-10',
  }
  const classNames = cx('rounded-full border-2 border-slate-200 shadow-lg h-auto', sizes[size])
  return <img src={src} alt={alt} className={classNames} />
}
