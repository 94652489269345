import { Follower, Organization, Repository, User } from '../types/user'

const GITHUB_API_URL = 'https://api.github.com'
const GITHUB_USERS_ROUTE = `${GITHUB_API_URL}/users`

type Params = {
  per_page?: string
}

/**
 * Retrieves a list of users.
 *
 * @return {Promise<User[]>} A promise that resolves to an array of User objects.
 */
export const getUsers = async (): Promise<User[]> => {
  // return require('../data/getUsers.json')
  const response = await fetch(GITHUB_USERS_ROUTE)
  const data = await response.json()
  return data
}

export const getUserDetails = async (userName: string): Promise<any> => {
  // return require('../data/getUserDetails.json')
  return await Promise.all([
    getUserByUserName(userName),
    getOrgsByUserName(userName),
    getReposByUserName(userName),
    getFollowersByUserName(userName, { per_page: '5' }),
  ]).then(([user, orgs, repos, followers]) => {
    return { ...user, orgs: orgs, repos: repos, top_followers: followers }
  })
}

/**
 * Fetches a user's data from the GitHub API based on their username.
 *
 * @param {string} userName - The username of the user.
 * @return {Promise<User[]>} - A promise that resolves to the user's data.
 */
const getUserByUserName = async (userName: string): Promise<User[]> => {
  const response = await fetch(`${GITHUB_USERS_ROUTE}/${userName}`)
  const data = await response.json()
  return data
}

/**
 * Retrieves the organizations a user belongs to.
 *
 * @param {string} userName - The username of the user.
 * @return {Promise<Organization[]>} - A promise that resolves with the organizations data.
 */
const getOrgsByUserName = async (userName: string): Promise<Organization[]> => {
  const response = await fetch(`${GITHUB_USERS_ROUTE}/${userName}/orgs`)
  const data = await response.json()
  return data
}

/**
 * Retrieves a list of repositories by the specified username.
 *
 * @param {string} userName - The username of the user.
 * @param {Repository[]} repos - The array of repositories. Used for recurrsive fetching.
 * @param {number} page - The page number. Used for recurrsive fetching.
 * @returns {Promise<Repository[]>} A promise that resolves with an array of repositories.
 */
const getReposByUserName = async (
  userName: string,
  repos: Repository[] = [],
  page: number = 1
): Promise<Repository[]> => {
  const response = await fetch(`${GITHUB_USERS_ROUTE}/${userName}/repos?per_page=100&page=${page}`)
  const data = await response.json()

  if (data.length < 100) {
    return repos.concat(data)
  }

  return getReposByUserName(userName, repos.concat(data), page + 1)
}

/**
 * Retrieves the followers of a user by their username.
 *
 * @param {string} userName - The username of the user.
 * @return {Promise<Follower[]>} A promise that resolves to an array of followers.
 */
const getFollowersByUserName = async (userName: string, params: Params): Promise<Follower[]> => {
  let followers_url = `${GITHUB_USERS_ROUTE}/${userName}/followers`
  if (params) {
    followers_url = `${followers_url}?${new URLSearchParams(params)}`
  }
  const response = await fetch(followers_url)
  const data = await response.json()
  return data
}
