import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { getUserDetails } from '../../api/users'
import {
  Avatar,
  ExternalLink,
  List,
  ListItem,
  Page,
  Section,
  SectionHeading,
  UserCard,
} from '../core'

import { Follower, Organization, Repository } from '../../types/user'

const userQuery = (login: string) => ({
  queryKey: ['user', login],
  queryFn: async () => getUserDetails(login),
})

export const userLoader =
  (queryClient: any) =>
  async ({ params }: any) => {
    const query = userQuery(params.userName)

    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  }

export const UserView = () => {
  const params = useParams()
  const {
    data: { orgs: organizations, repos: repositories, top_followers, ...user },
  } = useQuery(userQuery(params.userName || ''))

  return (
    <Page className="p-10">
      <UserCard avatarUrl={user.avatar_url} userName={user.login} followers={user.followers} />

      {repositories.length > 0 && (
        <Section>
          <SectionHeading>Repositories</SectionHeading>
          <List
            items={repositories.map(({ id, name, html_url }: Repository) => (
              <ListItem key={id}>
                <ExternalLink href={html_url}>{name}</ExternalLink>
              </ListItem>
            ))}
          />
        </Section>
      )}

      {organizations.length > 0 && (
        <Section>
          <SectionHeading>Organizations</SectionHeading>
          <List
            items={organizations.map(({ id, login, avatar_url }: Organization) => (
              <ListItem key={id}>
                <ExternalLink href={`https://github.com/${login}`}>
                  <Avatar src={avatar_url} alt={login} size="small" />
                  {login}
                </ExternalLink>
              </ListItem>
            ))}
          />
        </Section>
      )}

      {top_followers.length > 0 && (
        <Section>
          <SectionHeading>Top 5 Followers</SectionHeading>
          <List
            items={top_followers.map(({ id, html_url, login, avatar_url }: Follower) => (
              <ListItem key={id}>
                <ExternalLink href={html_url}>
                  <Avatar src={avatar_url} alt={login} size="small" />
                  {login}
                </ExternalLink>
              </ListItem>
            ))}
          />
        </Section>
      )}
    </Page>
  )
}
