import cx from 'classnames'
import { Avatar } from './'

type UserCardProps = {
  avatarUrl: string
  userName: string
  followers?: number
}

export const UserCard = ({ avatarUrl, followers, userName }: UserCardProps) => {
  const classNames = cx('flex', {
    'flex-col sm:flex-row sm:items-center': followers,
    'items-center': !followers,
  })
  return (
    <div className={classNames}>
      <Avatar src={avatarUrl} alt={`${userName}'s avatar`} />
      <h3 className="flex-1 font-bold">{userName}</h3>
      {followers && <p>{followers.toLocaleString()} followers</p>}
    </div>
  )
}
